<template>
  <section id="wrapper">
    <div 
      class="login-register"
    >
      <div class="login-box card">
        <div class="card-body">
          <div id="loginform" class="text-center">
            <img src="images/logo-jasa-raharja.png" />
            <h3 class="mt-5">
              Reset Your Password
            </h3>
            <p class="text-center">Please enter your new password</p>
          </div>
          <ValidationObserver v-slot="{handleSubmit}" ref="VFormReset">
            <b-overlay
              :show="loadingOverlay"
              rounded="sm"
            >
              <form 
                @submit.prevent="handleSubmit(doResetPassword)" 
                class="form-horizontal form-material" 
                id="loginform"
              > 
                <div class="form-group">
                  <label class="control-label mb-1">
                    Password
                    <a href="javascript:;" class="ml-1 icon-information" v-b-tooltip.hover.noninteractive="'Password harus mengandung abjad, angka, dan simbol. Minimal 8 karakter.'"><svg width="23" height="23" viewBox="0 0 20 20" fill="#000" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 17a7 7 0 100-14 7 7 0 000 14zm8-7a8 8 0 11-16 0 8 8 0 0116 0z" clip-rule="evenodd"></path><path d="M10.93 8.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533l1.002-4.705z"></path><circle cx="10" cy="6.5" r="1"></circle></svg></a>
                  </label>
                  <input 
                    class="form-control allow-enter" 
                    type="password"
                    placeholder="Password" 
                    v-model="input.password" 
                  /> 
                  <VValidate 
                    name="Password" 
                    vid="confirmation"
                    v-model="input.password" 
                    :rules="{required: true, regex: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/}" 
                  />
                </div>
                <div class="form-group">
                  <label class="control-label mb-1">
                    Confirm Password
                  </label>
                  <input 
                    class="form-control allow-enter" 
                    type="password"
                    placeholder="Confirmation Password" 
                    v-model="input.confirm_password" 
                  /> 
                  <VValidate 
                    name="Confirmation Password" 
                    v-model="input.confirm_password" 
                    rules="required|confirmed:confirmation"
                  />
                </div>
                <div class="form-group text-center">
                  <div class="col-xs-12 p-b-20">
                    <button 
                      type="submit" 
                      class="btn btn-block btn-lg btn-info btn-rounded"
                    >
                      Reset Password
                    </button>
                  </div>
                </div>
                <div class="form-group m-b-0">
                  <div class="col-sm-12 text-center"> 
                    {{ config.app.copyright()}} © 
                    {{ config.app.company }}. 
                    All Rights Reserved 
                  </div>
                </div>
              </form>
            </b-overlay>
          </ValidationObserver>
        </div>
      </div>
    </div>
</section>
</template>
<script>
  import Gen from '@libs/Gen'
  import Config from '@/libs/Config.js'
  export default {
    data(){
      return {
        loadingOverlay: false,
        emailSent: false,
        input: {},
        countDown: 0,
        tokenExist: false
      }
    },
    methods: {
      doSubmit(){
        this.$refs.VForm.validate().then(success => {
          if(!success) return 

          this.loadingOverlay = true
          Gen.apiRest('/do/forgot-password', { data: {email: this.input.email} }, 'POST')
          .then(res => {            
            if(res.data.afp_id){

              global.Swal.fire({
                title: 'Email has been sent',
                icon: 'success',
                confirmButtonText: 'Ok'
              })

              this.countDown = 60
              this.emailSent = true
              this.countDownTimer()
            }
            this.loadingOverlay = false
          })
          .catch(err => {
            global.Swal.fire({
              title: err.response.data.message,
              icon: 'error',
              confirmButtonText: 'Ok'
            })
            this.loadingOverlay = false
          })
        })
      },
      resendEmail(){
        this.loadingOverlay = true
        Gen.apiRest('/do/resend-email-forgot', { data: {email: this.input.email} }, 'POST')
          .then(res => {            
            if(res.data.afp_id){
              global.Swal.fire({
                title: 'Email has been sent',
                icon: 'success',
                confirmButtonText: 'Ok'
              })
              this.countDown = 60
              this.emailSent = true
              this.countDownTimer()
            }
            this.loadingOverlay = false
          })
          .catch(err => {
            global.Swal.fire({
              title: err.response.data.message,
              icon: 'error',
              confirmButtonText: 'Ok'
            })
            this.loadingOverlay = false
          })
      },
      countDownTimer () {
        if (this.countDown > 0) {
            setTimeout(() => {
                this.countDown -= 1
                this.countDownTimer()
            }, 1000)
        }
      },
      checkToken(){
        Gen.apiRest('/do/check-token-reset', { data: {t: this.$route.query.t} }, 'POST')
          .then(res => {
            if(res.data.status == 'ok'){
              this.tokenExist = true
            }else{
              global.Swal.fire({
                title: res.data.message,
                text: res.data.desc,
                icon: 'error'
              }).then(() => {
                this.$router.push({name: 'Login'}).catch()
              })
            }
          })
          .catch(() => {
            this.$router.push({name: 'Login'}).catch()
          })
      },
      doResetPassword(){
        this.$refs.VFormReset.validate().then(success => {
          if(!success) return

          this.loadingOverlay = true;
          Gen.apiRest('/do/reset-password', { data: {password: this.input.password, confirm_password: this.input.confirm_password, t: this.$route.query.t} }, 'POST')
          .then(res => {            
            this.loadingOverlay = false
            global.Swal.fire({
              title: res.data.title,
              text: res.data.message,
              icon: 'success',
              confirmButtonText: 'Ok'
            }).then(() => {
              this.$router.push({name: 'Login'})
            })
          })
          .catch(err => {
            this.loadingOverlay = false
            global.Swal.fire({
              title: err.response.data.message,
              icon: 'error',
              confirmButtonText: 'Ok'
            })
          })
        })
        console.log('hello')
      }
    },  
    computed: {
      config(){
        return Config
      }
    },
    mounted(){
      if(this.$route.query.t){
        this.checkToken()
      }
    },
  }
</script>
<style scoped>
@import "../assets/css/pages/login-register-lock.css";
</style>
